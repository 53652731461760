import React from "react"
import { useTranslation } from "react-i18next"

import "assets/sass/contact.scss"
import Layout from "components/Layout"
import Seo from "components/Seo"
import ContactForm from "components/Contact/ContactForm"
import ContactInfos from "components/Contact/ContactInfos"
import ContactMap from "components/Contact/ContactMap"

const ContactPage = () => {
  const { t } = useTranslation()
  const title = t("Contact UVASCLAB")
  const seoDescription = t(
    "Do you have a medical software application project? Please contact us!"
  )

  return (
    <Layout
      hasHeader
      hasPreFooter={false}
      subtitle={t("Do you need information?")}
      title={title}
    >
      <Seo title={title} description={seoDescription} />
      <ContactForm />
      <ContactInfos />
      <ContactMap />
    </Layout>
  )
}

export default ContactPage
