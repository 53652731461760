import React from "react"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"

import "assets/sass/map.scss"
import Logo from "images/logo-uvasc-color.inline.svg"

const ContactMap = () => {
  const center = [48.21604812588104, -3.6996839564359076]
  const position = [47.864784622391866, -4.222478684627571]

  const marker = marker => {
    if (marker) {
      setTimeout(() => marker.openPopup(position), 1000)
    }
  }

  return (
    <section className="container">
      {typeof window !== "undefined" && (
        <MapContainer center={center} scrollWheelZoom={false} zoom={8}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} ref={marker}>
            <Popup
              autoClose={false}
              closeButton={false}
              closeOnClick={false}
              closeOnEscapeKey={false}
            >
              <Logo />
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </section>
  )
}

export default ContactMap
