import React from "react"
import { useTranslation } from "react-i18next"

import Envelope from "images/envelope.inline.svg"
import Instagram from "images/instagram-contact.inline.svg"
import Linkedin from "images/linkedin-contact.inline.svg"
import Mark from "images/mark.inline.svg"
import Smartphone from "images/smartphone.inline.svg"
import Youtube from "images/youtube-contact.inline.svg"

const ContactForm = () => {
  const { t } = useTranslation()

  return (
    <section className="customer-infos text-center">
      <div className="container">
        <p className="bold-30 mb-1">UVASCLAB</p>
        <p className="bold-20 mb-3">{t("CUSTOMER SERVICE")}</p>
        <div className="row regular-20">
          <div className="col">
            <Envelope className="mb-2" width="20" />
            <a className="d-block mb-2" href="mailto:contact@uvasc.io">
              contact@uvasc.io
            </a>
            <div className="contact-rs">
              <a
                href="https://www.instagram.com/uvasclab/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram height="41" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCBI_i5fHNPAczokVX1_aXYg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Youtube height="51" className="ms-1" />
              </a>
              <a
                href="https://www.linkedin.com/company/uvasc-lab/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin width="41" className="ms-1" />
              </a>
            </div>
          </div>
          <div className="col">
            <Smartphone className="mb-2" width="20" />
            <a className="d-block mb-2" href="tel:06 73 86 49 25">
              +33 (0)6 73 86 49 25
            </a>
            <p>{t("Plan a call")}</p>
          </div>
          <div className="col">
            <Mark className="mb-2" width="20" />
            <p className="lh-2">Hôtel-Dieu</p>
            <p className="lh-2">de Pont-l'Abbé</p>
            <p className="lh-2">Rue Roger Signor</p>
            <p className="lh-2">29120 Pont-l'Abbé</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
